import { type UseQueryResult, useQuery } from 'react-query';
import {
  BffService,
  // eslint-disable-next-line camelcase
  type Wealthlane_ReportService_Dtos_ParticipantDashboardDto,
} from '../../services/wealthlane-report-services';

const useGetParticipantDashBoard = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  // eslint-disable-next-line camelcase
  Wealthlane_ReportService_Dtos_ParticipantDashboardDto | undefined,
  unknown
> => {
  const participantDashBoard = useQuery(['getParticipantDashBoard'], async () => {
    return await handleRequest(BffService.getApiReportApiAppBFFParticipantDashboard());
  });
  return participantDashBoard;
};

export default useGetParticipantDashBoard;
