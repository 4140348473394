import { type UseQueryResult, useQuery } from 'react-query';
import {
  TradePreClearanceService,
  //  eslint-disable-next-line camelcase
  type Wealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceParticipantDto,
} from '../../services/wealthlane-award-services';

const useGetParticipantTradePreClearance = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<
  //  eslint-disable-next-line camelcase
  Wealthlane_AwardService_Dtos_TradePreClearance_TradePreClearanceParticipantDto | undefined,
  unknown
> => {
  const participantTradePreClearance = useQuery(['getParticipantTradePreClearance'], async () => {
    return await handleRequest(
      TradePreClearanceService.getApiAwardApiAppTradePreClearanceParticipantTradePreClearance()
    );
  });
  return participantTradePreClearance;
};

export default useGetParticipantTradePreClearance;
