import { useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import * as signalR from '@microsoft/signalr';
import { ReactComponent as Logo } from '../../assets/svg/LogoHeader.svg';

import {
  NavMenuContainer,
  NavMenuCon,
  NavMenuMobileContainer,
  ProfileDropdownContainer,
  ProfileDrop,
  ProfileDropdownMenu,
  DropDownMenuSuperAdmin,
  ProfileDropWrapper,
  HeaderNotice,
  NavbarContainerMain,
  MobileNavItem,
  DropDownMenuAdmin,
} from './navbar.styled';
import AuthService from '../../services/authService';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthRoutesList } from '../../pages/configuration/routeSlice';
import Heading from '../typography';
import Notification from './notification';
import {
  useBoolean,
  useGetSignUpInformation,
  useGetUnseenNotificationCount,
  useWealthlaneCustomerApi,
  useWealthlaneNotificationApi,
  useGetParticipantPersonalDetails,
} from '../../hooks';
import AnimationWrapper from '../animation/animationWrapper';
import NotificationModal from './notification/notificationModal';
import Button from '../button';
// eslint-disable-next-line camelcase
import { type Wealthlane_NotificationService_DTOs_BellNotificationDto } from '../../services/wealthlane-notification-services';
import { selectToken } from '../../auth/authSlice';
import { setNotify } from '../../auth/notify';
import { TransactionsList } from '../../constants/innerRoutesList';

interface NavbarType {
  isAuth: boolean;
  showNotify: boolean;
  isNotify: boolean;
}

const Navbar = ({ isAuth, showNotify, isNotify }: NavbarType): JSX.Element => {
  const dispatch = useDispatch();
  const authService = new AuthService();
  const token = useSelector(selectToken);
  const userAuthList = useSelector(selectAuthRoutesList);
  const { handleRequest: info } = useWealthlaneCustomerApi();
  const { data: signUpInfo } = useGetSignUpInformation(info, token);
  const onboarding = signUpInfo?.isOnBoarded;
  const { data: personalInfo } = useGetParticipantPersonalDetails(info, onboarding);

  const [isLoggedIn, setLoggedIn] = useState(true);
  const { handleRequest: NotificationHandleRequest } = useWealthlaneNotificationApi();
  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const { data: NotificationCount, refetch } = useGetUnseenNotificationCount(
    NotificationHandleRequest,
    token
  );
  const [toggleTransactions, setToggleTransactions] = useState(false);

  const [openSideNav, setOpenSideNav] = useState(false);
  const [open, setOpen] = useState(false);
  const [newMessage, setNewMessage] =
    // eslint-disable-next-line camelcase
    useState<Wealthlane_NotificationService_DTOs_BellNotificationDto>({});

  const onSideNavClick = (isClose?: boolean): void => {
    setOpenSideNav(!openSideNav);
    if (isClose ?? false) {
      document.getElementsByTagName('html')[0].style.overflow = 'unset';
    } else {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    }
  };
  const { handleRequest } = useWealthlaneCustomerApi();
  const { data } = useGetSignUpInformation(handleRequest, token);

  const wrapperRef: any = useRef(null);
  useEffect(() => {
    function handleClickOutside(event: Event): void {
      if (
        Boolean(wrapperRef.current) &&
        !(wrapperRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setOpenSideNav(false);
        setOpen(false);
        setToggleTransactions(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (showNotify) {
      document.body.style.paddingTop = '101px';
    } else {
      document.body.style.paddingTop = '67px';
    }
  }, [showNotify]);

  const storageEventHandler = (): void => {
    dispatch(setNotify({ notify: localStorage.getItem('Notify') === 'Yes' }));
  };

  useEffect(() => {
    dispatch(setNotify({ notify: localStorage.getItem('Notify') === 'Yes' }));
    window.addEventListener('storage', storageEventHandler, false);
  }, []);

  const handleRemoveNotify = (): void => {
    localStorage.setItem('Notify', 'No');
    dispatch(setNotify({ notify: false }));
  };

  useEffect(() => {
    if (isModalOpen) setLoggedIn(false);
  }, [isModalOpen]);

  useEffect(() => {
    if (isLoggedIn && isNotify) {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `${process.env.REACT_APP_WEALTHLANE_API_ENDPOINT}/api/notification/signalr-hubs/bellNotification`,
          {
            transport: signalR.HttpTransportType.LongPolling,
            accessTokenFactory: () => token ?? '',
          }
        )
        .configureLogging(signalR.LogLevel.Information)
        .build();

      const start = async (): Promise<void> => {
        try {
          await connection.start();
        } catch (err) {
          setTimeout(() => {
            void start();
          }, 5000);
        }
      };

      connection.onclose(() => {
        void start();
      });

      connection.on('ReceiveBellNotification', (user, message) => {
        void refetch();
        setNewMessage(message);
      });

      // Start the connection.
      void start();
    }
  }, [isLoggedIn]);

  return (
    <>
      {showNotify && (
        <HeaderNotice>
          <Heading
            variant='body-s'
            title={
              <span className='flex gap-x-2  '>
                Need help? Contact us at
                <a href='support@wealthlane.co' className='underline text-blue-600'>
                  support@wealthlane.co
                </a>
              </span>
            }
          />
          <span
            className='material-symbols-outlined closeIcon'
            onClick={() => {
              handleRemoveNotify();
            }}>
            close
          </span>
        </HeaderNotice>
      )}
      <NavbarContainerMain hasNotify={showNotify} className='nav'>
        <Link to='/'>
          <Logo className='nav-brand' />
        </Link>
        {(data?.isOnBoarded ?? false) && (
          <>
            {isAuth && token.length > 0 && (
              <>
                <div className='nav-mobile items-center'>
                  <Notification
                    NotificationCount={NotificationCount}
                    isModalOpen={isModalOpen}
                    openModal={() => {
                      openModal();
                    }}
                  />
                  <Button
                    title='Menu'
                    variant='secondary'
                    icon='menu'
                    onClick={() => {
                      onSideNavClick(false);
                    }}
                    btnClassName='nav-menu'
                  />
                  <NavMenuMobileContainer
                    className={openSideNav ? 'top-0' : '-top-[120%] opacity-0'}>
                    <MobileNavItem className='static navMenuItem'>
                      <Heading variant='h6' title='Menu' className='nav-mobile--heading' />
                      <div className='nav-items'>
                        <NavLink
                          to='/overview'
                          className={({ isActive }: { isActive: boolean }) =>
                            isActive ? 'active p-2' : ''
                          }
                          onClick={() => {
                            onSideNavClick(true);
                          }}>
                          <Heading variant='body' title='Overview' />
                        </NavLink>
                        <NavLink
                          to='/holdings'
                          className={({ isActive }: { isActive: boolean }) =>
                            isActive ? 'active p-2' : ''
                          }
                          onClick={() => {
                            onSideNavClick(true);
                          }}>
                          <Heading variant='body' title='My Portfolio' />
                        </NavLink>
                      </div>
                    </MobileNavItem>
                    <MobileNavItem className='static navMenuItem'>
                      <Heading
                        variant='h6'
                        title={userAuthList?.currentUser?.name}
                        className='nav-mobile--heading'
                      />
                      <div className='nav-items'>
                        <NavLink
                          to='/profile'
                          className={({ isActive }) => (isActive ? 'active p-2' : '')}
                          onClick={() => {
                            onSideNavClick(true);
                          }}>
                          <Heading variant='body' title='My Profile' />
                        </NavLink>
                        <NavLink
                          to='/settings'
                          className={({ isActive }: { isActive: boolean }) =>
                            isActive ? 'active p-2' : ''
                          }
                          onClick={() => {
                            onSideNavClick(true);
                          }}>
                          <Heading variant='body' title='Security' />
                        </NavLink>
                        <NavLink
                          to='/notification'
                          className={({ isActive }: { isActive: boolean }) =>
                            isActive ? 'active p-2' : ''
                          }
                          onClick={() => {
                            onSideNavClick(true);
                          }}>
                          <Heading variant='body' title='Notification' />
                        </NavLink>
                        <NavLink
                          to='/financial-institution'
                          className={({ isActive }: { isActive: boolean }) =>
                            isActive ? 'active p-2' : ''
                          }
                          onClick={() => {
                            onSideNavClick(true);
                          }}>
                          <Heading variant='body' title='Financial Institution' />
                        </NavLink>
                        <NavLink
                          to='/e-delivery'
                          className={({ isActive }: { isActive: boolean }) =>
                            isActive ? 'active p-2' : ''
                          }
                          onClick={() => {
                            onSideNavClick(true);
                          }}>
                          <Heading variant='body' title='E-Delivery' />
                        </NavLink>
                        <Heading
                          variant='body'
                          title='Log Out'
                          onClick={async () => {
                            await authService.logout();
                          }}
                          className='cursor-pointer'
                        />
                        <div className='nav-close'>
                          <Button
                            title='Close'
                            variant='primary-dark'
                            icon='close'
                            onClick={() => {
                              onSideNavClick(true);
                            }}
                            btnClassName='nav-mobile--close'
                          />
                        </div>
                      </div>
                    </MobileNavItem>
                  </NavMenuMobileContainer>
                </div>
                <NavMenuContainer className='relative nav-main'>
                  <NavMenuCon className='static navMenuItem'>
                    <NavLink
                      to='/overview'
                      className={({ isActive }: { isActive: boolean }) =>
                        isActive ? 'active' : ''
                      }>
                      Overview
                    </NavLink>
                  </NavMenuCon>
                  <NavMenuCon className='static navMenuItem'>
                    <NavLink
                      to='/holdings'
                      className={({ isActive }: { isActive: boolean }) =>
                        isActive ? 'active' : ''
                      }>
                      My Portfolio
                    </NavLink>
                  </NavMenuCon>

                  <div
                    className='flex items-center gap-x-2 relative'
                    onClick={() => {
                      setToggleTransactions(!toggleTransactions);
                    }}>
                    <NavMenuCon className='static navMenuItem'>
                      <NavLink
                        to='/transactions'
                        className={({ isActive }) =>
                          isActive ? 'active pointer-events-none' : 'pointer-events-none'
                        }>
                        Transactions
                      </NavLink>
                      {/* <div
                        className={
                          linkClicked
                            ? 'link-active pointer-events-none link-text'
                            : 'pointer-events-none link-text'
                        }>
                        Transactions
                      </div> */}
                    </NavMenuCon>
                    {toggleTransactions && (
                      <ProfileDropdownMenu ref={wrapperRef} className='nav-subMenu'>
                        {TransactionsList.map((item: { url: string; name: string }) => (
                          <DropDownMenuAdmin>
                            <NavLink
                              to={item.url}
                              onClick={() => {
                                setToggleTransactions(!toggleTransactions);
                              }}>
                              <h1 className='para' role='button'>
                                {item.name}
                              </h1>
                            </NavLink>
                          </DropDownMenuAdmin>
                        ))}
                      </ProfileDropdownMenu>
                    )}
                  </div>
                  {/* <NavMenuCon className='static navMenuItem'>
                    <NavLink
                      to='/transactions'
                      className={({ isActive }: any) => isActive && 'active'}>
                      Transactions
                    </NavLink>
                  </NavMenuCon> */}
                  {/* <NavMenuCon className='static navMenuItem'>
                    <NavLink
                      to='/release-transactions'
                      className={({ isActive }: any) => isActive && 'active'}>
                      Release Transactions
                    </NavLink>
                  </NavMenuCon> */}
                </NavMenuContainer>
                <ProfileDropdownContainer className='nav-main'>
                  <Notification
                    NotificationCount={NotificationCount}
                    isModalOpen={isModalOpen}
                    openModal={() => {
                      openModal();
                    }}
                  />
                  <ProfileDropWrapper>
                    <ProfileDrop
                      onClick={() => {
                        setOpen(!open);
                      }}>
                      <div className='font-semibold title'>
                        {personalInfo?.firstName != null
                          ? `${personalInfo?.firstName} ${personalInfo?.lastName ?? ''}`
                          : '-'}
                      </div>
                      <span>
                        <span className='material-symbols-rounded downIcon'>arrow_drop_down</span>
                      </span>
                    </ProfileDrop>
                    {open && (
                      <ProfileDropdownMenu ref={wrapperRef}>
                        <Link
                          to='/profile'
                          onClick={() => {
                            setOpen(false);
                          }}>
                          <DropDownMenuSuperAdmin>My Profile</DropDownMenuSuperAdmin>
                        </Link>
                        <Link
                          to='/settings'
                          onClick={() => {
                            setOpen(false);
                          }}>
                          <DropDownMenuSuperAdmin>Security</DropDownMenuSuperAdmin>
                        </Link>
                        <Link
                          to='/notification'
                          onClick={() => {
                            setOpen(false);
                          }}>
                          <DropDownMenuSuperAdmin>Notification</DropDownMenuSuperAdmin>
                        </Link>
                        <Link
                          to='/financial-institution'
                          onClick={() => {
                            setOpen(false);
                          }}>
                          <DropDownMenuSuperAdmin>Financial Institution</DropDownMenuSuperAdmin>
                        </Link>
                        <Link
                          to='/pre-clearance-request'
                          onClick={() => {
                            setOpen(false);
                          }}>
                          <DropDownMenuSuperAdmin>Pre-Clearance Request</DropDownMenuSuperAdmin>
                        </Link>
                        <DropDownMenuSuperAdmin
                          className='logoutBtn'
                          onClick={() => {
                            // authService.logout();
                            authService
                              .logout()
                              .then(() => {})
                              .catch(() => {});
                          }}>
                          <div className='para'>Logout</div>
                        </DropDownMenuSuperAdmin>
                      </ProfileDropdownMenu>
                    )}
                  </ProfileDropWrapper>
                </ProfileDropdownContainer>
              </>
            )}
          </>
        )}
      </NavbarContainerMain>
      <AnimationWrapper>
        {isModalOpen && (
          <NotificationModal
            handleRequest={handleRequest}
            isModalOpen={isModalOpen}
            newMessage={newMessage}
            closeModal={closeModal}
          />
        )}
      </AnimationWrapper>
    </>
  );
};

export default Navbar;
