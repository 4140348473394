import React, { useState } from 'react';
import { COLORS } from '../../constants/style/color';
import {
  Content,
  SettingFooter,
  StepContent,
  StepNumber,
  StepTitle,
  StepWrapper,
} from './signUp.styled';
import Heading from '../../components/typography/index';
import { StepOne, StepTwo, StepThree, StepFour } from './signup-steps';
import { Link, Navigate } from 'react-router-dom';
import blueGradient from '../../assets/images/bluegradient.png';
import useWealthlaneCustomerApi from '../../hooks/useWealthlaneCustomerApi';
import { useSelector } from 'react-redux';
import { useGetSignUpInformation } from '../../hooks';
import { selectToken } from '../../auth/authSlice';

const SignUp = (): JSX.Element => {
  const [stepCount, setStepCount] = useState<number>(1);
  const [formData, setFormData] = useState([]);
  const { handleRequest } = useWealthlaneCustomerApi();
  const token = useSelector(selectToken);
  const { data: participantsInformation } = useGetSignUpInformation(handleRequest, token);

  const stepOptions = [
    {
      label: 'Verify Your Identity',
      value: 1,
    },
    {
      label: 'Verify Your Email',
      value: 2,
    },
    {
      label: 'Set Up Your Credentials',
      value: 3,
    },
    {
      label: 'Set Up Your Security Questions',
      value: 4,
    },
  ];
  if (participantsInformation?.isOnBoarded ?? false) {
    return <Navigate to={`/overview`} />;
  }

  return (
    <div>
      <Content
        className=''
        style={{ background: `url(${blueGradient}) no-repeat`, backgroundSize: 'cover' }}>
        {/* <SignUpConfirmation >
          <img src={userIcon} alt="" />
          <Heading variant={'h3'} title={'You have successfully registered to Wealthlane.'} />
          <Button variant={'primary'} type='button' title={'Log In to your Account'} />
        </SignUpConfirmation> */}
        <div>
          <Heading
            variant={'h6'}
            title={'ACCOUNT REGISTRATION'}
            className='registration-sub'
            color={`rgba(113,113,113,0.6)`}
          />
          <Heading
            variant={'h2'}
            title={'Please complete the following steps to register your account.'}
            color={`${COLORS.Black}`}
          />

          {/* stepper ui */}
          <StepWrapper>
            <StepContent>
              {stepOptions.map((item) => (
                <div
                  className={`step ${item.value < stepCount ? 'prev' : ''} ${
                    item.value === stepCount ? 'active' : ''
                  }`}
                  onClick={() => {
                    setStepCount(
                      item.value < stepCount && item.value !== 1 ? item.value : stepCount
                    );
                  }}>
                  <StepTitle className=' cursor-pointer'>
                    <StepNumber className='step-number'>
                      {item.value < stepCount ? (
                        <span className='material-symbols-outlined'>check_small</span>
                      ) : (
                        <span>{item.value}</span>
                      )}
                    </StepNumber>
                    <div className='step-text'>
                      <Heading variant={'h6'} title={item.label} color={`${COLORS.Silver}`} />
                    </div>
                  </StepTitle>
                  <span className='material-symbols-outlined step-icon'>chevron_right</span>
                </div>
              ))}
            </StepContent>
          </StepWrapper>
          <div>
            {stepCount === 1 ? (
              <StepOne setStep={setStepCount} formData={formData} setFormData={setFormData} />
            ) : stepCount === 2 ? (
              <StepTwo setStep={setStepCount} />
            ) : stepCount === 3 ? (
              <StepThree setStep={setStepCount} />
            ) : stepCount === 4 ? (
              <StepFour />
            ) : (
              ''
            )}
          </div>
        </div>
        <SettingFooter>
          <Heading
            variant='body-xs'
            title='© Wealthlane 2022 All right copyright reserved.'
            color={`${COLORS.SpanishGray}`}
          />

          <ul>
            <li>
              <Link to='/'>
                <Heading variant='body-xs' title='About' color={`${COLORS.SpanishGray}`} />
              </Link>
            </li>
            <li>
              <Link to=''>
                <Heading variant='body-xs' title='Contact' color={`${COLORS.SpanishGray}`} />
              </Link>
            </li>
          </ul>
        </SettingFooter>
      </Content>
    </div>
  );
};

export default SignUp;
